html[theme="light"] {
    --body-background-color: #f0f0f0;
    --body-color: #000;
    --content-background-color: #fff;
    --sidenav-background-color: #0A2558;
    --sidenav-color: #fff;
    --sidenav-hover-background-color: #f0f0f0;
    --sidenav-hover-color: #000;
}

html[theme="dark"] {
    --body-background-color: #1a1c22;
    --body-color: #e9e9e9;
    --content-background-color: #23242a;
    --sidenav-background-color: #1a1c22;
    --sidenav-color: #74767b;
    --sidenav-hover-background-color: #1B428C;
    --sidenav-hover-color: #e9e9e9;
}

body {
    font-family: Calibri, Arial, Helvetica, sans-serif;
    margin: 0;
    background-color: var(--body-background-color);
    text-align: center;
    color: var(--body-color);
}

input[type="submit"] {
    background-color: var(--sidenav-hover-background-color);
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
    color: var(--sidenav-hover-color);
    cursor: pointer;
    padding: 6px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    &:hover,
    &:focus,
    &:disabled {
        background-color: var(--sidenav-background-color);
        color: var(--sidenav-color);

        &:disabled {
            cursor: not-allowed;
        }
    }
}

.content {
    background-color: var(--content-background-color);
    text-align: center;
    position: absolute;
    padding: 0px 60px 20px 60px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

a {
    color: var(--body-color);
}